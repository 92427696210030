import React from "react";
import { subResourceIntegrity } from "../client/Graphql/hash";
function extractMetaTag(metaTagString) {
    if (metaTagString) {
        var nameMatch = metaTagString.match(/name="([^"]*)"/);
        var contentMatch = metaTagString.match(/content="([^"]*)"/);
        var name_1 = nameMatch ? nameMatch[1] : '';
        var content = contentMatch ? contentMatch[1] : '';
        return { name: name_1, content: content };
    }
    return null;
}
export default function Html(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18;
    var siteConfig = _a.siteConfig, pageData = _a.pageData, browserDetails = _a.browserDetails, children = _a.children, title = _a.title, xApiKey = _a.xApiKey, cachedAPI = _a.cachedAPI, apiBaseUrl = _a.apiBaseUrl, sseBaseUrl = _a.sseBaseUrl, analyticsBaseUrl = _a.analyticsBaseUrl, wssBaseUrl = _a.wssBaseUrl, env = _a.env, ip = _a.ip, embedCodeToken = _a.embedCodeToken, toolsUrl = _a.toolsUrl, templateUrl = _a.templateUrl;
    var fontUrl = (_d = (_c = (_b = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _b === void 0 ? void 0 : _b.brand) === null || _c === void 0 ? void 0 : _c.font) === null || _d === void 0 ? void 0 : _d.fontUrl;
    var embeddedStyles = (_e = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _e === void 0 ? void 0 : _e.features.embeddedStyles;
    var outputStyles = embeddedStyles ? embeddedStyles.replace(/^<style\b[^>]*>|<\/style>$/g, '') : null;
    var extractedMeta = extractMetaTag(embeddedStyles);
    var name = extractedMeta === null || extractedMeta === void 0 ? void 0 : extractedMeta.name;
    var content = extractedMeta === null || extractedMeta === void 0 ? void 0 : extractedMeta.content;
    var fontFamily = ((_h = (_g = (_f = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _f === void 0 ? void 0 : _f.brand) === null || _g === void 0 ? void 0 : _g.font) === null || _h === void 0 ? void 0 : _h.fontFamily) || "AcuminProRegular";
    var jsonLDFlag = false;
    var convertSecondsToHms = function (d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        var hDisplay = h > 0 ? h + "H" : "";
        var mDisplay = m > 0 ? m + "M" : "";
        var sDisplay = s > 0 ? s + "S" : "";
        return 'PT' + hDisplay + mDisplay + sDisplay;
    };
    var fullPageData = (pageData === null || pageData === void 0 ? void 0 : pageData.page) || {};
    var type = "website";
    if (fullPageData.title === "Video Page") {
        type = "video.movie";
    }
    else if (fullPageData.title === "Show Page") {
        type = "video.tv_show";
    }
    else if (fullPageData.title === "Article Page") {
        type = "article";
    }
    else if (fullPageData.title === "Event Page" || fullPageData.title === "Game Detail Page") {
        type = "event";
    }
    var uploadDate, duration, pageTitle, description = "", thumbnailArray = [];
    pageTitle = (_k = (_j = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _j === void 0 ? void 0 : _j.metadataMap) === null || _k === void 0 ? void 0 : _k.title;
    if (pageTitle === null) {
        pageTitle = ((_l = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _l === void 0 ? void 0 : _l.title) || title;
    }
    if (!((_m = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _m === void 0 ? void 0 : _m.isLiveStream) && (((_o = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _o === void 0 ? void 0 : _o.contentType) === 'VIDEO')) {
        jsonLDFlag = true;
        var videoTitle = (_p = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _p === void 0 ? void 0 : _p.title;
        pageTitle = videoTitle === null || videoTitle === void 0 ? void 0 : videoTitle.replace(/["']/g, ""); //Replace Both Double and Single Quotes
        var videoDescription = (_q = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _q === void 0 ? void 0 : _q.description;
        description = videoDescription === null || videoDescription === void 0 ? void 0 : videoDescription.replace(/["']/g, ""); //Replace Both Double and Single Quotes
        var videoUploadDate = new Date((_r = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _r === void 0 ? void 0 : _r.uploadDate);
        uploadDate = videoUploadDate.toISOString();
        duration = convertSecondsToHms((_s = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _s === void 0 ? void 0 : _s.duration);
        var thumbnailUrl = (_t = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _t === void 0 ? void 0 : _t.thumbnailUrl;
        var thumbnailValues = thumbnailUrl && Object.values(thumbnailUrl);
        var thumbnailString = thumbnailValues && thumbnailValues.filter(function (ele) { return ele; }).toString();
        if (thumbnailString && thumbnailString.includes("impolicy")) {
            var srcSplit = thumbnailString.split("impolicy");
            thumbnailString = "".concat(srcSplit[0], "impolicy=resize&w=1920&h=1080");
        }
        else {
            thumbnailString = "".concat(thumbnailString, "?impolicy=resize&w=1920&h=1080");
        }
        thumbnailArray = thumbnailString && thumbnailString.split(",");
    }
    var jsonLDInput;
    if (jsonLDFlag) {
        jsonLDInput = "{\n      \"@context\": \"https://schema.org\",\n      \"@type\": \"VideoObject\",\n      \"name\": \"".concat(pageTitle, "\",\n      \"description\": \"").concat(description, "\",\n      \"thumbnailUrl\": ").concat(JSON.stringify(thumbnailArray), ",\n      \"uploadDate\": \"").concat(uploadDate, "\",\n      \"duration\": \"").concat(duration, "\",\n      \"contentUrl\": \"https://").concat(browserDetails.host).concat((_u = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _u === void 0 ? void 0 : _u.metadataMap.contentUrl, "\"\n    }");
    }
    var appData = "window.apiBaseUrl=".concat(JSON.stringify(apiBaseUrl), "; window.sseBaseUrl=").concat(JSON.stringify(sseBaseUrl), "; window.xApiKey=").concat(JSON.stringify(xApiKey), "; window.cachedAPI=").concat(JSON.stringify(cachedAPI), "; window.app_data = ").concat(JSON.stringify(siteConfig), "; window.page_data = ").concat(JSON.stringify(pageData), "; window.browserDetails = ").concat(JSON.stringify(browserDetails), "; window.analyticsBaseUrl=").concat(JSON.stringify(analyticsBaseUrl), ";  window.wssBaseUrl=").concat(JSON.stringify(wssBaseUrl), "; window.env=").concat(JSON.stringify(env), "; window.ip=").concat(JSON.stringify(ip), "; window.embedCodeToken=").concat(JSON.stringify(embedCodeToken), "; window.toolsUrl=").concat(JSON.stringify(toolsUrl), "; window.templateUrl=").concat(JSON.stringify(templateUrl));
    return (React.createElement("html", { lang: "en", style: { "margin": 0 } },
        React.createElement("head", null,
            React.createElement("meta", { charSet: "utf-8" }),
            React.createElement("meta", { httpEquiv: "x-dns-prefetch-control", content: "on" }),
            React.createElement("title", null, pageTitle),
            name && content && React.createElement("meta", { name: name, content: content }),
            React.createElement("meta", { property: "og:url", content: "".concat(('https://' + (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.host) + (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.path)) || (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.host) || "") }),
            React.createElement("meta", { name: "viewport", content: "width=device-width, initial-scale=1" }),
            React.createElement("meta", { name: "description", content: "".concat(((_w = (_v = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _v === void 0 ? void 0 : _v.metadataMap) === null || _w === void 0 ? void 0 : _w.description) || "") }),
            React.createElement("meta", { name: "keywords", content: "".concat(((_y = (_x = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _x === void 0 ? void 0 : _x.metadataMap) === null || _y === void 0 ? void 0 : _y.keywords) || "") }),
            React.createElement("meta", { property: "og:type", content: "".concat(((_0 = (_z = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _z === void 0 ? void 0 : _z.metadataMap) === null || _0 === void 0 ? void 0 : _0.contentType) ? ((_2 = (_1 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _1 === void 0 ? void 0 : _1.metadataMap) === null || _2 === void 0 ? void 0 : _2.contentType).toLowerCase() : "website") }),
            React.createElement("meta", { property: "og:title", content: "".concat(pageTitle || "") }),
            React.createElement("meta", { property: "og:description", content: "".concat(((_4 = (_3 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _3 === void 0 ? void 0 : _3.metadataMap) === null || _4 === void 0 ? void 0 : _4.description) || "") }),
            React.createElement("meta", { property: "og:site_name", content: "".concat(((_5 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _5 === void 0 ? void 0 : _5.site) || "") }),
            React.createElement("meta", { name: "apple-mobile-web-app-capable", content: "yes" }),
            React.createElement("meta", { name: "apple-mobile-web-app-status-bar-style", content: "black" }),
            React.createElement("meta", { name: "apple-mobile-web-app-title", content: "".concat(((_6 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _6 === void 0 ? void 0 : _6.site) || "") }),
            React.createElement("meta", { property: "og:image", content: "".concat(((_9 = (_8 = (_7 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _7 === void 0 ? void 0 : _7.metadataMap) === null || _8 === void 0 ? void 0 : _8.thumbnailUrl) === null || _9 === void 0 ? void 0 : _9.r16x9) || ((_11 = (_10 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _10 === void 0 ? void 0 : _10.images) === null || _11 === void 0 ? void 0 : _11.placeholderCover), "?impolicy=resize&w=1920&h=1080") }),
            React.createElement("link", { rel: "shortcut icon", type: "image/x-icon", href: "".concat(((_14 = (_13 = (_12 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsPlatform) === null || _12 === void 0 ? void 0 : _12.images) === null || _13 === void 0 ? void 0 : _13.favicon) === null || _14 === void 0 ? void 0 : _14.imageUri) || "") }),
            React.createElement("link", { rel: "canonical", href: "".concat(('https://' + (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.host) + (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.path))) }),
            React.createElement("meta", { name: "twitter:card", content: "summary_large_image" }),
            React.createElement("meta", { name: "twitter:site", content: "@".concat(((_15 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _15 === void 0 ? void 0 : _15.site) || "") }),
            React.createElement("meta", { name: "twitter:creator", content: "@".concat(((_16 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _16 === void 0 ? void 0 : _16.site) || "") }),
            React.createElement("meta", { property: "twitter:title", content: "".concat(pageTitle || "") }),
            React.createElement("meta", { property: "twitter:description", content: "".concat(((_18 = (_17 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _17 === void 0 ? void 0 : _17.metadataMap) === null || _18 === void 0 ? void 0 : _18.description) || "") }),
            fontUrl && React.createElement("link", { href: "".concat(fontUrl, "&display=swap"), rel: "stylesheet" }),
            React.createElement("link", { rel: "preconnect", href: "https://www.googletagmanager.com" }),
            React.createElement("link", { rel: "preconnect", href: "https://sp.auth-staging.adobe.com" }),
            React.createElement("link", { rel: "preconnect", href: "https://api.auth.adobe.com" }),
            React.createElement("link", { rel: "preconnect", href: "https://entitlement.auth-staging.adobe.com" }),
            React.createElement("link", { rel: "preconnect", href: "https://uswidgets.fn.sportradar.com" }),
            embeddedStyles && !(embeddedStyles.includes("meta")) && React.createElement("style", { dangerouslySetInnerHTML: { __html: outputStyles || "" } })),
        React.createElement("body", { className: "site-background-color site-color", style: {
                "margin": 0,
                "overflowX": "hidden",
                "textRendering": "auto",
                "fontSize": "1em",
                "fontFamily": "".concat(fontFamily, " !important")
            } },
            React.createElement("noscript", { dangerouslySetInnerHTML: {
                    __html: "<b>Enable JavaScript to run this app.</b>"
                } }),
            children,
            React.createElement("script", { dangerouslySetInnerHTML: {
                    __html: appData
                }, integrity: subResourceIntegrity(appData) }),
            jsonLDFlag ? (React.createElement("script", { type: "application/ld+json", dangerouslySetInnerHTML: {
                    __html: jsonLDInput
                }, integrity: subResourceIntegrity(jsonLDInput) })) : '')));
}
