let config = {
    buildNumber: "2441",
    cachedAPI: "https://api-cache.prod-livgolfplus.viewlift.com",
    apiBaseUrl:"https://api.prod-livgolfplus.viewlift.com",
    apiKey:  "WX41iaJiOw7hJW8sNbDP5JpVwmjaH6t6y3xbQUsc",
    analyticsBaseUrl:"https://analytics-api.prod-livgolfplus.viewlift.com/user-beacon",
    sseBaseUrl: "https://event.prod-livgolfplus.viewlift.com",//'https://event-livgolfplus.viewlift.com',
    wssBaseUrl: "wss://ws.prod-livgolfplus.viewlift.com",//'wss://ws-livgolfplus.viewlift.com',
    appCMSBaseUrl: "https://appcms.prod-livgolfplus.viewlift.com",
    buildAssetsUrl: "https://appcms.asset.viewlift.com",
    toolsUrl:"https://cms.viewlift.com",
    paymentWSUrl: "wss://prod-ws.viewlift.com/ws",
    internalEmbedBaseURL: "https://internal.api.viewlift.com",
    filesProxy: {
        directPaths: [
          '/apple-app-site-association',
          '/.well-known/apple-app-site-association',
          '/.well-known/assetlinks.json',
          '/robots.txt',
          '/ads.txt',
          '/app-ads.txt',
          '/sitemap1.xml',
          '/sitemap2.xml',
          '/sitemap3.xml',
          '/sitemap4.xml',
          '/sitemap5.xml',
          '/sitemap6.xml',
          '/sitemap7.xml',
          '/sitemap8.xml',
          '/sitemap9.xml',
          '/sitemap10.xml',
          '/offline.html',
          '/serviceWorker.js',
          '/sw.js'
        ]
      },
    env:"prod"
   }
   module.exports.config = config;
