import analytics from '@viewlift/analytics/analytics-core/index.cjs'
import googleAnalytics from '@viewlift/analytics/google-analytics/index.cjs'
import userBeacon from '@viewlift/analytics/user-beacon/index.cjs'

  export const ANALYTICSEVENTS = {
    LOGIN: 'login',
    LOGIN_INIT:'login-init',
    LOGOUT: 'logout',
    USER_CREATED:'  user creation',
    GUEST_USER_TVE_LOGIN:'guest user tve login',
    GUEST_USER_TVE_LOGIN_SUCCESS:'guest user tve login success',
    GUEST_USER_TVE_LOGIN_FAILURE:'guest user tve login fail',
    ADD_TO_WATCHLIST: 'add-to-watchlist',
    SHARE:'share',
    SUBSCRIBE:'subscribe-now',
    GET_VERIFICATION_CODE:'get-verification-code',
    SUBMIT_VERIFICATION_CODE:'submit-verification-code',
    REMOVE_FROM_WATCHLIST:'remove-from-watchlist',
    REMOVE_ALL_WATCHLIST:'remove-all-from-watchlist',
    REMOVE_ALL_WATCHHISTORY:'remove-all-from-watch-history',
    DETAIL_PAGE_VIEW:'detail-page-view',
    HOME_PAGE_VIEW:'home-page-view',
    SEARCH:'search',
    LIKE_CONTENT:'like',
    DISLIKE_CONTENT:'dislike',
    DELETE_ACCOUNT:'delete-account',
    UPDATE_NAME:'update-name',
    SET_GAME_ALERTS:'set-game-alerts',
    CUSTOMER_FEEDBACK:'customer-feedback',
    REMOVE_DEVICE:'remove-device',
    REMOVE_ALL_DEVICES:'remove-all-devices',
    UPDATE_EMAIL:'update-email',
    UPDATE_MOBILE:'update-mobile-number',
    VERIFY_MOBILE:'verify-moble-number',
    VERIFY_EMAIL:'verify-email',
    ITEM_CLICK:'click-on-item',
    PAGE_VIEW:'page-view',
    LOGIN_FAILURE:'login-failure',
    APPLY_COUPON:'apply-code-status',
    PERSONALIZATION_POP_UP: "personalization-pop-up",
    PERSONALIZATION_EDIT_POP_UP: "personalization-edit-pop-up",
    ADD_PERSONALIZATION: "add-personalization",
    USER_LOCATION:'user location',
    NEW_ONBOARDING_CHECKOUT_INIT:'checkout-init', // New onboarding Event starts from here
    NEW_ONBOARDING_CHECKOUT_SUCCESS:'checkout-success',
    NEW_ONBOARDING_CHECKOUT_FAILURE:'checkout-failure',
    NEW_ONBOARDING_CHECKOUT_CANCELED:'checkout-canceled',
    PLAN_SELECTION: 'plan-selection',
    PLAN_UPDATE: 'plan-update',
    OFFER_CODE_APPLIED: 'offer-code-applied',
    ADD_PAYMENT_INFO:'add-payment-info',
    PAYMENT_INITIATE: 'payment-initiate',
    PAYMENT_SUCCCESS:'payment-success',
    PAYMENT_PENDING:'payment-pending',
    PAYMENT_FAILURE:'payment-failure',
    LOCATION_VERFIY: 'location-verify',
    LOCATION_IN_RANGE: 'location-in-range',
    LOCATION_OUT_RANGE: 'location-out-range',
    CARD_ADDED: 'card-added',
    SUBSCRIBE_CLICKED: 'subscribe-clicked',
    EMAIL_USER_CONSENT: 'user-consent',
    TVE_LOGIN_INITIATE: 'tve-login-init',
    TVE_LOGIN_SUCCESS: 'tve-login-success',
    TVE_LOGIN_FAILURE: 'tve-login-failure',
    CHECKOUT_USER_DETAIL_ADDED: 'checkout-user-detail-added',
    CHECKOUT_USER_VERIFICATION: 'checkout-user-verification',
    CHECKOUT_USER_LOGIN_INIT: 'checkout-user-login-init',
    CHECKOUT_USER_LOGIN_SUCCESS: 'checkout-user-login-success',
    CHECKOUT_USER_LOGIN_FAILURE: 'checkout-user-login-failure',
    CHECKOUT_USER_CREATE: 'checkout-user-create',
    CHECKOUT_USER_CREATE_SUCCESS: 'checkout-user-create-success',
    CHECKOUT_USER_CREATE_FAILURE: 'checkout-user-create-failure',
    USER_MERGE: 'user-merge',
    TVE_VL_LOGIN_INIT: 'tve-vl-login-init',
    TVE_VL_LOGIN_SUCCESS: 'tve-vl-login-success',
    TVE_VL_LOGIN_FAILURE: 'tve-vl-login-failure'
  };

  export const FB_EVENTS = {
    PAGE_VIEW: 'PageView',
    PURCHASE: 'Purchase',
    ADD_PAYMENT_INFO: 'AddPaymentInfo',
    INITIATE_CHECKOUT: 'InitiateCheckout',
    COMPLETE_REGISTRATION: 'CompleteRegistration',
    ADD_TO_CART: 'AddToCart',
    START_TRIAL: 'StartTrial',
    SUBSCRIBE: 'Subscribe',
    VIEW_CONTENT: 'ViewContent',
    ADD_TO_WISHLIST: 'AddToWishlist',
    REMOVED_FROM_WATCHLIST: 'RemovedFromWatchlist',
    SUBSCRIPTION_INITIATED: 'SubscriptionInitiated',
    SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted',
    SUBSCRIPTION_FAILED: 'SubscriptionFailed',
    TVOD_PURCHASE: 'tvod_purchase',
    TVOD_PURCHASE_COMPLETION: 'tvod_purchase_completion',
    SEARCH: 'Search',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    PLAY_STARTED: 'Play Started',
    WATCHED: 'Watched',
  };
  
  export const pixel_Events = {
    ADD_TO_CART: 'AddToCart',
    PAGE_VIEW: 'PageView',
    PURCHASE: 'Purchase',
    ADD_PAYMENT_INFO: 'Add Payment Info',
    INITIATE_CHECKOUT: 'Initiate Checkout',
    COMPLETE_REGISTRATION: 'Complete Registration',
    SUBSCRIPTION_INITIATED: "SubscriptionInitiated",
    SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted'
  };
  

export default function trackEvent(eventName, payload,trackingID) {
  if (typeof window !== 'undefined') { 
    analytics({
      app: 'analytics',
      eventName: eventName,
      plugins: [
       googleAnalytics({
         trackingId: 'G-FGXC3XVLJK',
         initialize: () => {
         },
         payload: payload
       }),
     ]
    })
  } else {
    console.log("Analytics Tracking is supported on client side only")
  }
}

export function trackUserEvent(payload) {
  if (typeof window !== 'undefined') { 
    return analytics({
      app: 'user-analytics',
      apiBaseUrl: window?.app_data?.appcmsMain?.apiConfig?.userBeaconUrl,
      plugins: [
       userBeacon({
         initialize: () => {
           console.log('FIRE INIT FROM PLUGIN USER')
         },
         payload: payload,
       }),
     ]
    })
  } else {
    console.log("Analytics Tracking is supported on client side only")
  }
}