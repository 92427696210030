
export const generateHash=(key)=>{
    var shajs = require('sha.js');
    let hash=shajs('sha256').update(key).digest('hex');   
    return hash;
  }

  export const subResourceIntegrity = (key)=>{
    let input = generateHash(key)
    let output = 'sha256-'+input
    return output;
  }

  export const generateMinifiedQuery = (query)=>{
    return query.replace(/\s+/g, ' ').trim();
  }