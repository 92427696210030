 export const loadSiteStyles = () => {
   let styleElm = document.documentElement.style
   let brand = window?.app_data?.appcmsMain?.brand
    styleElm.setProperty("--siteColor", brand?.general?.textColor)
    styleElm.setProperty("--siteBackgroundColor", brand?.general?.backgroundColor)
    styleElm.setProperty("--siteSecondaryCtaBackgroundColor",brand?.cta?.secondary?.backgroundColor)
    styleElm.setProperty("--siteSecondaryCtaTextColor",brand?.cta?.secondary?.textColor)
    styleElm.setProperty("--siteSecondaryCtaBorderColor",brand?.cta?.secondary?.border?.color)
    styleElm.setProperty("--siteBorderColor", brand?.cta?.primary?.border?.color)
    styleElm.setProperty("--siteBorderWidth", brand?.cta?.primary?.border?.width)
    // styleElm.setProperty("--siteBorderRadius", brand?.cta?.primary?.)
    // styleElm.setProperty("--siteBorderStyle", brand?.cta?.primary?.)
    styleElm.setProperty("--siteCtaColor",brand?.cta?.primary?.textColor)
    styleElm.setProperty("--siteCtaBackgroundColor", brand?.cta?.primary?.backgroundColor)
    // Event State - Background & text color
    styleElm.setProperty("--defaultEventBackgroundColor", brand?.scheduleCards?.states?.default)
    styleElm.setProperty("--defaultEventTextColor", brand?.scheduleCards?.states?.defaultText)
    styleElm.setProperty("--liveEventBackgroundColor", brand?.scheduleCards?.states?.live)
    styleElm.setProperty("--liveEventTextColor", brand?.scheduleCards?.states?.liveText)
    // Error Page
    styleElm.setProperty("--errorBackgroundColor", brand?.errorPage?.errorBackgroundColor)
    styleElm.setProperty("--errorGradientColor", brand?.errorPage?.errorGradientColor)
    styleElm.setProperty("--footerActiveLinkColor", brand?.footer?.linkActive?.textColor)
    // Fonts
    styleElm.setProperty("--primaryFontFamily", brand?.font?.fontFamily)
    // Banner Color
    styleElm.setProperty("--siteBannerBackgroundColor", (brand?.general?.bannerBackground ?? "#1A4392"))
    styleElm.setProperty("--footerLinkTextColor", (brand?.footer?.link?.textColor ?? "#1A4392"))
 }