/**
 * The function `logger` logs messages with different levels (info, error, warn, debug) based on the
 * environment.
 * @param {string | number | object} message
 * @param {'info' | 'warn' | 'error' | 'debug'} [level=info]
 * @returns void
 */
export default function logger(message, level = 'info') {
  if (process.env.NODE_ENV === 'production') return;

  const timestamp = new Date().toISOString();
  const logMessage = `[${level.toUpperCase()}] | ${timestamp} | ${typeof message === "object" ? JSON.stringify(message) : message}`;

  switch (level) {
    case 'error':
      return console.error(logMessage);
    case 'warn':
      return console.warn(logMessage);
    case 'debug':
      return console.debug(logMessage);
    case 'info':
      return console.info(logMessage);
    default:
      return console.log(logMessage);
  }
}
